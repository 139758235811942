import { useEffect, useState } from "react";
import {
  ComposableMap,
  Geographies,
  Geography,
  ZoomableGroup,
  Line,
  Marker
} from "react-simple-maps"

const geoUrl =
  "https://raw.githubusercontent.com/zcreativelabs/react-simple-maps/master/topojson-maps/world-110m.json";



const MapChart = ({ markers }) => {
  const [lat, setLat] = useState(null);
  const [lng, setLng] = useState(null);
  const [status, setStatus] = useState(null);
  const [me, setMe] = useState([])
  const getLocation = () => {
    if (!navigator.geolocation) {
      setStatus('Geolocation is not supported by your browser');
    } else {
      setStatus('Locating...');
      navigator.geolocation.getCurrentPosition((position) => {
        setStatus(null);
        setLat(position.coords.latitude);
        setLng(position.coords.longitude);
        setMe([{
          markerOffset: -30,
          name: "Me",
          coordinates: [position.coords.longitude, position.coords.latitude]
        }])
      }, () => {
        setStatus('Unable to retrieve your location');
      });
    }
  }

  useEffect(() => {
    if (status == null) getLocation()
  }, [])
  return (
    <ComposableMap
      projection="geoAzimuthalEqualArea"
      projectionConfig={{
        rotate: [-20.0, -52.0, 0],
        scale: 1300
      }}
    >
      <ZoomableGroup center={[10, 50]}>
        <Geographies geography={geoUrl}>
          {({ geographies }) =>
            geographies
              .map(geo => (
                <Geography
                  key={geo.rsmKey}
                  geography={geo}
                  style={{
                    default: { outline: "none" },
                    hover: { outline: "none" },
                    pressed: { outline: "none" },
                  }}
                  fill="#9998A3"
                  stroke="#EAEAEC"
                />
              ))
          }
        </Geographies>
        {/* {lat === null && lng === null ? null : <Line
          from={[lng, lat]}
          to={[0, 51]}
          stroke="#e63624"
          strokeWidth={2}
          strokeLinecap="round"
        />} */}
        {markers.map(({ name, coordinates }) => (

          <Marker key={name} coordinates={coordinates} onClick={() => {
            console.log(name, coordinates)
          }} >
            <marker id="arrow" viewBox="0 0 10 10" refX="5" refY="5"
              markerWidth="6" markerHeight="6"
              orient="auto-start-reverse">
              <path d="M 0 0 L 10 5 L 0 10 z" />
            </marker>
            {/* <svg
              xmlns="http://www.w3.org/2000/svg"
              width="21.333"
              height="37.218"
              version="1.1"
              viewBox="0 0 20 34.892"
            >
              <g transform="translate(-814.596 -274.386)">
                <g
                  fillOpacity="1"
                  transform="matrix(1.18559 0 0 1.18559 -151.177 -57.398)"
                >
                  <path
                    fill="#ff4646"
                    stroke="#d73534"
                    strokeDasharray="none"
                    strokeMiterlimit="4"
                    strokeOpacity="1"
                    strokeWidth="1"
                    d="M817.112 282.971c-1.258 1.343-2.046 3.299-2.015 5.139.064 3.845 1.797 5.3 4.568 10.592.999 2.328 2.04 4.792 3.031 8.873.138.602.272 1.16.335 1.21.062.048.196-.513.334-1.115.99-4.081 2.033-6.543 3.031-8.871 2.771-5.292 4.504-6.748 4.568-10.592.031-1.84-.759-3.798-2.017-5.14-1.437-1.535-3.605-2.67-5.916-2.717-2.312-.048-4.481 1.087-5.919 2.621z"
                    display="inline"
                    opacity="1"
                  ></path>
                  <circle
                    cx="823.031"
                    cy="288.253"
                    r="3.035"
                    fill="#590000"
                    strokeWidth="0"
                    display="inline"
                    opacity="1"
                  ></circle>
                </g>
              </g>
            </svg> */}
            {/* <g
              fill="none"
              stroke="#FF5533"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            // transform="translate(-12, -24)"
            >
              <circle cx="12" cy="10" r="3" />
              <path d="M12 21.7C17.3 17 20 13 20 10a8 8 0 1 0-16 0c0 3 2.7 6.9 8 11.7z" />
            </g> */}
            <text
              textAnchor="middle"
              y={0}
              style={{ fontSize: "48px"}}
            >
              {"📍"}
            </text>
          </Marker>
        ))}
      </ZoomableGroup>

    </ComposableMap>
  );
};

export default MapChart