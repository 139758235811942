import { IonHeader, IonTitle, IonToolbar } from '@ionic/react';

interface ContainerProps {
  pageTitle: string
}

const Title: React.FC<ContainerProps> = ({pageTitle}) => {
  return (
    <IonHeader collapse="condense">
    <IonToolbar>
      <IonTitle style={{textAlign: "center"}}size="large">{pageTitle}</IonTitle>
    </IonToolbar>
  </IonHeader>
  );
};

export default Title;
