import { IonContent, IonPage, IonFab, IonFabButton, IonIcon, IonCard, IonCardHeader, IonCardTitle, IonCardContent } from '@ionic/react';
import { RouteComponentProps } from "react-router-dom"
import { add } from 'ionicons/icons';
import '../css/Feed.css';
import Map from '../components/Map';
import Title from '../components/Title'
import Post from '../components/Post'

interface FeedPageProps
  extends RouteComponentProps<{
    id: string;
  }> { }

let posts = [{
  title: "Post 1",
  postText: "Something really cool and awesome",
  imgUrl: "https://i.pinimg.com/originals/59/4e/fe/594efe058d90001191e2385f1a0063f4.jpg"
},{
  title: "Post 2",
  postText: "Something really cool and awesome",
  imgUrl: "https://i.pinimg.com/originals/59/4e/fe/594efe058d90001191e2385f1a0063f4.jpg"
},{
  title: "Post 3",
  postText: "Something really cool and awesome",
  imgUrl: "https://i.pinimg.com/originals/59/4e/fe/594efe058d90001191e2385f1a0063f4.jpg"
},{
  title: "Post 4",
  postText: "Something really cool and awesome",
  imgUrl: "https://i.pinimg.com/originals/59/4e/fe/594efe058d90001191e2385f1a0063f4.jpg"
},{
  title: "Post 5",
  postText: "Something really cool and awesome",
  imgUrl: "https://i.pinimg.com/originals/59/4e/fe/594efe058d90001191e2385f1a0063f4.jpg"
},{
  title: "Post 6",
  postText: "Something really cool and awesome",
  imgUrl: "https://i.pinimg.com/originals/59/4e/fe/594efe058d90001191e2385f1a0063f4.jpg"
}]

const Feed: React.FC<FeedPageProps> = ({ match }) => {
  return (
    <IonPage>
      <Title pageTitle="Moments" />
      <IonContent>
        <Map markers={[{ name: "Italy", coordinates: [12.5674,41.8718]}, { name: "France", coordinates: [2.2137,46.2276]}, { name: "Germany", coordinates: [10.4515,51.1657]}, { name: "Austria", coordinates: [14.5501,47.5162]}]}/>
        <div className='feedContainer'>
          {posts.map((p,i) => {
            return <Post {...p} key={`${i}-post`} />
          })}

        </div>

      </IonContent>
      <IonFab vertical="bottom" horizontal="end" slot="fixed">
        <a href='/capture'><IonFabButton>
          <IonIcon icon={add} />
        </IonFabButton>
        </a>
      </IonFab>
    </IonPage>
  );
};

export default Feed;
