import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonButton, IonGrid, IonRow, IonCol, IonImg} from '@ionic/react';
import { useEffect } from 'react';
import { usePhotoGallery } from '../hooks/usePhotoGallery';
import '../css/Capture.css';
import { supabase } from '../config/supabaseClient';



const Home: React.FC = () => {
  const updatePics = async () => {
    const { data, error } = await supabase
      .storage
      .from('moments-captured')
      .list()
    console.log(data)
    if(error){
      console.log(error)
    }
  }
  const { photos, takePhoto } = usePhotoGallery();
  useEffect(() => {
    updatePics()
  }, [])
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Moments</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Moments</IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonButton expand="block" onClick={takePhoto}>Capture</IonButton>
        <IonGrid>
          <IonRow>
            {photos.map((photo, index) => (
              <IonCol size="6" key={index}>
                <IonImg src={photo.webviewPath} />
              </IonCol>
            ))}
          </IonRow>
        </IonGrid>
      </IonContent>

    </IonPage>
  );
};

export default Home;
