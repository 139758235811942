
import MapChart from './MapChart'
import '../css/Map.css'

interface MapProps {
  markers: object[]
}

const Map: React.FC<MapProps> = ({markers}) => {
    return (
      <div className='mapContainer'>
        <MapChart markers={markers}/>
      </div>
    );
   
}

export default Map;