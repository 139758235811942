import { IonContent, IonPage, IonButton, IonCard, IonCardHeader, IonCardTitle, IonCardContent, IonFab, IonFabButton, IonIcon } from '@ionic/react';
import { add } from 'ionicons/icons';
import '../css/New.css';

import Title from '../components/Title'

const New: React.FC = () => {
  return (
    <IonPage>
      <Title pageTitle="Moments" />
      <IonContent>
        <div style={{ marginTop: "15%" }}></div>
        <IonCard>
          <IonCardHeader>
            <IonCardTitle>Daily Feed</IonCardTitle>
          </IonCardHeader>

          <IonCardContent>
            Keep close with your friends by posting to daily updates to your shared feed.
          </IonCardContent>
          <IonButton expand="block">Create a new Daily feed</IonButton>
        </IonCard>
        <div style={{ marginTop: "5%" }}></div>
        <IonCard>
          <IonCardHeader>
            <IonCardTitle>Event Feed</IonCardTitle>
          </IonCardHeader>

          <IonCardContent>
            Create a private feed of pictures that anyone at your event can add to. Share the feed link by qr code or directly, anyone with a link can upload directly to the feed.
          </IonCardContent>
          <IonButton expand="block">Create a new Event feed</IonButton>
        </IonCard>
      </IonContent>
      <IonFab vertical="bottom" horizontal="end" slot="fixed">
        <a href='/capture'><IonFabButton>
          <IonIcon icon={add} />
        </IonFabButton>
        </a>
      </IonFab>
    </IonPage>
  );
};

export default New;
