import { useState, useEffect } from 'react';
import { isPlatform } from '@ionic/react';

import { Camera, CameraResultType, CameraSource, Photo } from '@capacitor/camera';
import { Filesystem, Directory } from '@capacitor/filesystem';
import { Storage } from '@capacitor/storage';
import { Capacitor } from '@capacitor/core';

import { supabase } from '../config/supabaseClient'


const uploadImage = async (path: string) => {
  const response = await fetch(path);
  const blob = await response.blob();

  const filename = path.substr(path.lastIndexOf("/") + 1);

  const { data, error } = await supabase.storage
    .from("moments-captured")
    .upload(`${filename}`, blob, {
      cacheControl: "3600",
      upsert: false,
    });

  if (error) alert(error?.message);

  console.log(data);


  return true;
};

export function usePhotoGallery() {
  const [photos, setPhotos] = useState<UserPhoto[]>([]);
  const takePhoto = async () => {
    const photo = await Camera.getPhoto({
      resultType: CameraResultType.Uri,
      source: CameraSource.Camera,
      quality: 100,
    });
    const fileName = new Date().getTime() + '.jpeg';
    console.log(photo)
    const newPhotos = [
      {
        filepath: fileName,
        webviewPath: photo.webPath,
      },
      ...photos,
    ];
    if(typeof(photo.webPath) === "string")
      uploadImage(photo.webPath)
    
    setPhotos(newPhotos);
  };

  return {
    photos,
    takePhoto,
  };
}

export interface UserPhoto {
  filepath: string;
  webviewPath?: string;
}