import { IonCard, IonCardHeader, IonCardTitle, IonCardContent } from '@ionic/react';
import Carousel from './ImageCarousel'
interface PostProps {
    title: string,
    postText: string,
    imgUrl: string
}

const Post: React.FC<PostProps> = ({ title, postText, imgUrl }) => {
    return (
        <IonCard>

            <IonCardHeader>
                <IonCardTitle>{title}</IonCardTitle>
            </IonCardHeader>

            <IonCardContent>
                {postText}
            </IonCardContent>
            <Carousel imgs={[imgUrl,imgUrl,imgUrl]} />
        </IonCard>
    );

}

export default Post;